import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeStudent } from "../../api/student";
import { useToggle } from "../../utils/hooks";
import Delete from "../Modal/Delete";
import Swal from 'sweetalert2';
import { postFormData } from "../../api/index.js";
import DeleteStudent from "../Modal/DeleteStudent";
import moment from "moment";
import { useDispatch } from "react-redux";
import { BsThreeDotsVertical } from 'react-icons/bs'; 
import {
  changeDiscipline,
  changeStudentData,
  changeStudentType,
} from "../../features/membership/MembershipSlice";
import { useEffect } from "react";

function StudentCardFull({ data, refresh, activeOption, setActiveOption }) {
  const [delId, setDelId] = useState("");
  const [show, delToggle] = useToggle(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isExpired, setIsExpired] = useState(true);
  const [isRenewal, setIsRenewal] = useState(true);
  const [isActiveMembership, setIsActiveMembership] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);

  const confirmDelete = (id) => {
    navigate(`/account-cancellation/student/${id}`);

    // ({ studentId: delId }).then((res) => {
    //   if (res?.code === 1) {
    //     toast.success(res?.message);
    //     refresh();
    //   } else {
    //     toast.error(res?.message);
    //   }
    // });
  };

  const handleRenewalDate = useCallback(() => {
    const expiryDate = moment(data?.membership_details?.valid_till);
    const today = moment();
    let difference = moment.duration(expiryDate.diff(today)).asDays();
    console.log(difference, "Data");
    let toHours = difference * 24 * 60 * 60;

    if (
      (Number(data?.membership_details?.membership_details?.no_of_days) > 30 &&
        difference < 30 &&
        difference > 0) ||
      (Number(data?.membership_details?.membership_details?.no_of_days) === 7 &&
        difference < 7 &&
        difference >= 1) ||
      (Number(data?.membership_details?.membership_details?.no_of_days) === 1 &&
        difference < 2 &&
        difference >= 1)
    ) {
      return true;
    }

    return false;
  }, [data]);

  const handleExpired = useCallback(() => {
    console.log(data, "Data");
    const expiryDate = moment(data?.membership_details?.valid_till);
    const today = moment();
    let difference = moment.duration(expiryDate.diff(today)).asHours();

    if (difference <= 0) {
      return true;
    }

    return false;
  }, [data]);

  const handleAnonymizeStudent = (studentId) => {
    toggleOptions()
    postFormData("user/anonymizeStudent", {
      studentId
    }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        refresh();
      } else {
        toast.error(res?.message);
      }
    });
  };
  const handleActiveInactiveStudent = (studentId, status) => {
    toggleOptions()
    postFormData("user/activeInactiveStudent", {
      studentId,
      status
    }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        refresh();
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    if (data) {
      const isExpiredd = handleExpired();

      const isRenewald = handleRenewalDate();

      const isActiveMembershipd =
        data?.trialAccountStatus === "3" &&
        data?.membership_details?.payment_status === "1";
      setIsActiveMembership(isActiveMembershipd);
      setIsExpired(isExpiredd);
      setIsRenewal(isRenewald);
    }
  }, [data, handleExpired, handleRenewalDate]);

  const [showOptions, setShowOptions] = useState(false); // State for options visibility

  const toggleOptions = () => {
    setActiveOption((prev) => (prev === data?.id ? null : data?.id));
  };

  console.log(isExpired, isRenewal, isActiveMembership, "Expired and renewla");
  return (
    <div className="student__details AllStudentsListing">
      <div className="col-sm-12 ">
        {" "}
        <button className="btn d-none" style={{ marginLeft: 10 }} onClick={toggleOptions}>
          <BsThreeDotsVertical />
        </button>
        {activeOption === data?.id && ( // Conditionally render options
          <div className="options-list d-none">
            <button  onClick={(e) => {
                  e.preventDefault(); // Prevent the default link behavior
                  Swal.fire({
                    title: 'Confirm to Anonymize',
                    text: 'You have the right to request deletion or anonymisation of your data. However, some data must be kept for legal reasons, such as Ofsted requirements. If you’d like us to anonymize your data, please click below.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleAnonymizeStudent(data?.id)
                    }
                  });
                }}>Anonimyze</button>
            <button onClick={(e) => {
                  e.preventDefault(); // Prevent the default link behavior
                  Swal.fire({
                    title: data?.isActive == 0 ? 'Confirm to Activate' : 'Confirm to Deactivate',
                    text: data?.isActive == 0 ? 'Are you sure you want to activate this student?' : 'Are you sure you want to deactivate this student?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleActiveInactiveStudent(data?.id, data?.isActive ? 0 : 1)
                    }
                  });
                }}>{data?.isActive == 1 ? 'Deactivate' : 'Activate'}</button>
          </div>
        )}

        {data?.isActive && (<>
        <button className="btn deleteButton">
          <img
            src="assets/img/delete.png"
            className=""
            alt=""
            width={"25px"}
            height="25px"
            onClick={() => {
              // setDelId(data?.id);
              // delToggle();
              confirmDelete(data?.id);
            }}
          />
        </button>
        
        
        <Link to={`/edit-student/${data?.id}`}>
          <img
            src="assets/img/edit.png"
            className="studentEditimg editStudentButton"
            alt=""
          />
        </Link></>
        )}
      </div>
      <p>
        <img
          src={
            data?.image?.includes("http")
              ? data?.image
              : "assets/img/headerlogo.png"
          }
          className="student__img"
          alt=""
        />
      </p>

      <span className="d-grid studentCardFull">
        <p className="d-grid">
          <span>student name</span>
          <span>
            <b>{data ? `${data?.first_name} ${data?.last_name}` : ""}</b>
          </span>
        </p>
        <p className="d-grid">
          <span>school venue</span>
          <span>
            <b>{data?.school_details?.name || "NA"}</b>
          </span>
        </p>

        <span>
          <p className="d-grid">
            <span>date of birth</span>
            <span>
              <b>
                {data?.dob
                  ? moment(data?.dob).format("DD MMM YYYY")
                  : data?.dob_year
                  ? moment(
                      `${data?.dob_year}-${data?.dob_month}-${data?.dob_day}`
                    ).format("DD MMM YYYY")
                  : ""}
                {data?.student_type ? ` (${data?.student_type})` : ""}
              </b>
            </span>
          </p>
        </span>
        <span>
          <p className="d-grid">
            <span>discipline</span>
            <span>
              <b>{data?.discipline_details?.name}</b>
            </span>
          </p>
        </span>
        <span>
          <p className="d-grid">
            <span>Status</span>
            <span>
              <b className={data?.isActive ? '' : 'text-danger'}>{data?.isActive ? 'Active' : 'Inactive'}</b>
            </span>
          </p>
        </span>

        {data?.trialAccountStatus === "3" && isActiveMembership ? (
          <span>
            <p className="d-grid">
              <span>Renewal Due Date:</span>
              <span>
                <b>
                  {data?.membership_details?.valid_till
                    ? moment(data?.membership_details?.valid_till).format(
                        "DD MMM YYYY"
                      )
                    : "NA"}
                </b>
              </span>
            </p>
          </span>
        ) : null}
        <p className="d-grid">
          <span>Membership Status</span>
          <span>
            <b
              className={
                (data?.trialAccountStatus === "3" && isExpired) ||
                (data?.trialAccountStatus === "3" && !isActiveMembership) ||
                data?.trialAccountStatus === "0" ||
                data?.trialAccountStatus === "2"
                  ? "text-danger"
                  : ""
              }
            >
              {data?.trialAccountStatus === "1"
                ? "Trial"
                : data?.trialAccountStatus === "2"
                ? "Trial Expired"
                : data?.trialAccountStatus === "0" ||
                  data?.membership_details === null
                ? "No membership"
                : (data?.trialAccountStatus === "3" && isExpired) ||
                  (data?.trialAccountStatus === "3" &&
                    data?.membership_details === null) ||
                  (data?.trialAccountStatus === "3" && !isActiveMembership)
                ? "Expired"
                : data?.trialAccountStatus === "3" &&
                  !isExpired &&
                  isActiveMembership
                ? "Active"
                : ""}
            </b>
          </span>
        </p>
        {data?.trialAccountStatus === "1" ||
        data?.trialAccountStatus === "2" ||
        data?.trialAccountStatus === "0" ||
        (data?.trialAccountStatus === "3" && isExpired) ||
        (data?.trialAccountStatus === "3" && !isActiveMembership) ? (
          <span
            className={"theme-color cursor-pointer fw-bold"}
            onClick={() => {
              (async () => {
                await localStorage.setItem(
                  "discipline",
                  data?.discipline_details?.id
                );
                await localStorage.setItem("student_type", data?.student_type);
                await localStorage.setItem(
                  "student_data",
                  JSON.stringify(data)
                );
                await localStorage.setItem("buyOrRenew", "buy");
              })();
              dispatch(changeStudentData(data));

              dispatch(changeDiscipline(data?.discipline_details?.id));
              dispatch(changeStudentType(data?.student_type));

              navigate(`/plans/${data?.id}`);
            }}
          >
            <span
              className={
                data?.trialAccountStatus === "0" ||
                (data?.trialAccountStatus === "3" && !isActiveMembership) ||
                (data?.trialAccountStatus === "3" && isExpired) ||
                data?.trialAccountStatus === "2" ||
                data?.membership_details === null
                  ? "text-danger "
                  : ""
              }
            >
              {data?.trialAccountStatus === "2" ||
              data?.trialAccountStatus === "1"
                ? "New Members Plan"
                : data?.trialAccountStatus === "0" ||
                  (data?.trialAccountStatus === "3" && !isActiveMembership) ||
                  (data?.trialAccountStatus === "3" && isExpired) ||
                  data?.membership_details === null
                ? "Upgrade to full membership"
                : ""}
            </span>
          </span>
        ) : isRenewal ? (
          <span
            className="theme-color cursor-pointer fw-bold"
            onClick={() => {
              (async () => {
                await localStorage.setItem(
                  "discipline",
                  data?.discipline_details?.id
                );
                await localStorage.setItem("student_type", data?.student_type);
                await localStorage.setItem(
                  "student_data",
                  JSON.stringify(data)
                );
                await localStorage.setItem("buyOrRenew", "renew");
              })();
              dispatch(changeDiscipline(data?.discipline_details?.id));
              dispatch(changeStudentType(data?.student_type));
              dispatch(changeStudentData(data));
              navigate(`/plans/${data?.id}`, {
                state: {
                  membershipId: data?.membership_details?.membership_level,
                },
              });
            }}
          >
            Renew Membership
          </span>
        ) : null}
        <span>
          <small
            className={
              (data?.trialAccountStatus === "3" && isRenewal) ||
              (data?.trialAccountStatus === "3" && isExpired)
                ? "text-danger"
                : ""
            }
          >
            {data?.trialAccountStatus === "3" &&
            data?.membership_details !== null &&
            isExpired
              ? `Your membership is expired. Kindly renew.`
              : data?.trialAccountStatus === "3" &&
                data?.membership_details !== null &&
                isRenewal
              ? `Your membership expires soon. Kindly renew.`
              : ""}
          </small>
        </span>
      </span>

      {delId && (
        <DeleteStudent
          show={show}
          handleShow={(val) => {
            if (val === "confirm") {
              confirmDelete();
            }
            delToggle();
          }}
        />
      )}
    </div>
  );
}

export default StudentCardFull;
