import React, { useCallback, useEffect, useState } from "react";
import SelectPayment from "../../component/Modal/SelectPayment";
import OrderSummary from "./../../component/Modal/OrderSummary";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCartList,
  DeleteCartItem,
  FetchCartList,
  GetCartClassListSelector,
  getEventAddedToCartListSelector,
  GetCartClassList,
  FetchCartEventList,
  FetchUserDetail,
  userDetailSelector,
} from "../slice.js";
import { useToggle } from "../../utils/hooks";
import { toast } from "react-toastify";
import Delete from "../../component/Modal/Delete";
import { Link, useNavigate } from "react-router-dom";
import { removeFromEventCart } from "../../api/Events";
import moment from "moment";
import ReadMore from "../../component/ReadMore/ReadMore";
import BackButton from "./../../component/BackButton";
import { BookingPayment } from "../../api/booking";
import { addStudentDataReducer } from "../student/StudentSlice";
import { postFormData } from "../../api";
import { currency } from "../../utils";
import Loader from "../../component/Loader";
function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const cartList = useSelector(GetCartClassListSelector);
  const eventList = useSelector(getEventAddedToCartListSelector);
  const [delId, setDelId] = useState("");
  const [delOpen, delToggle] = useToggle(false);
  const [bookingId, setBookingId] = useState("");
  const [page, setPage] = useState("");
  const [amounts, setAmounts] = useState({
    total_amount: 0,
    wallet_amount: 0,
    admin_amount: 0,
  });
  const detail = useSelector(userDetailSelector);
  const [tnc, setTnc] = useState(false);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const confirmDelete = () => {
    if (page === "event") {
      removeFromEventCart({ cart_id: JSON.stringify(delId) }).then((res) => {
        if (res?.code === 1) {
          toast.success(res?.message);
          getCart();
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      dispatch(DeleteCartItem({ cart_id: JSON.stringify(delId) })).then(
        (res) => {
          if (res?.payload?.code === 1) {
            toast.success(res?.payload?.message);
            getCart();
          } else {
            toast.error(res?.payload?.message);
          }
        }
      );
    }
  };

  const handleShow = () => {
    setShow(!show);
  };

  const getCart = useCallback(() => {
    dispatch(FetchCartEventList({}));
    dispatch(GetCartClassList({})).then((res) => {
      if (res?.payload?.code === 1) {
        console.log(res, "Cart list");
        setAmounts({
          total_amount: res?.payload?.data?.total_amount
            ? Number(res?.payload?.data?.total_amount)
            : 0,
          wallet_amount: res?.payload?.data?.wallet_amount
            ? Number(res?.payload?.data?.wallet_amount)
            : 0,
          admin_amount: res?.payload?.data?.cart_list
            ?.total_trial_account_charge
            ? Number(res?.payload?.data?.cart_list?.total_trial_account_charge)
            : 0.0,
        });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    getCart();
  }, [getCart]);

  useEffect(() => {
    dispatch(FetchUserDetail({}));
  }, [dispatch]);

  const handlePayment = () => {
    if (cartList?.length > 0) {
      setPage("class");
    } else {
      setPage("event");
    }
    let total_amount = amounts?.total_amount ? amounts?.total_amount : 0;
    if (total_amount > 0) {
      setSubmitting(true);
      let wallet_used_amount =
        amounts?.wallet_amount === 0
          ? 0
          : amounts.wallet_amount >=
            amounts?.total_amount + amounts?.admin_amount
          ? amounts?.total_amount + amounts?.admin_amount
          : amounts?.wallet_amount > 0 &&
            amounts.wallet_amount <=
              amounts?.total_amount + amounts?.admin_amount
          ? amounts?.wallet_amount
          : 0;

      const transactionAmount = parseFloat(
        total_amount + amounts?.admin_amount - wallet_used_amount
      )?.toFixed(2);

      const payload = {
        data: {
          payment_status: transactionAmount > 0 ? 0 : 1,
          total_amount,
          wallet_used_amount,
          transaction_amount: transactionAmount,
          currency: currency,
          merchant_support_email: detail?.email,
        },
        payment_for: "booking",
        api: "booking/makePayment",
      };

      console.log(payload, "Payload for Make payment");

      //  Make payment for the items in the cart with above payload
      BookingPayment({
        merchant_support_email: payload?.data.merchant_support_email,
      })
        .then((res) => {
          console.log(res, "Booking");
          setSubmitting(false);
          if (res?.code === 1) {
            const bookingId = res?.data?.booking_details?.id;
            setBookingId(bookingId);
            setPage(page);
          } else {
            toast.error(res?.message);
          }
          return res;
        })
        .then((re) => {
          console.log(re, "Res");
          if (re?.code === 1) {
            console.log(Number(payload?.data?.transaction_amount));

            // if the wallet amount is sufficient then transaction amount will be 0 and hence we will show the order summary page.
            if (
              !re?.data?.payment_link &&
              Number(payload?.data?.transaction_amount) === 0
            ) {
              setModalName("orderSummary");
            } else if (re?.data?.payment_link) {
              // if wallet amount is not sufficient then the remaining amount will be in the transaction amount and we will redirect user to sqaure for that payment
              let bookingId = re?.data?.booking_details?.id;
              (async () => {
                await localStorage.setItem(
                  "bookingMembershipData",
                  JSON.stringify({
                    payment_for: "booking",
                    bookingId: bookingId,
                  })
                );
                dispatch(addStudentDataReducer(payload));
              })();

              let URI = re?.data?.payment_link?.paymentLink?.url;
              window.open(URI, "_self");
            }

            getCart();

            toast.success(re?.message);
          } else {
            toast.error(re?.message);
          }
        });

      // (async () => {
      //     await localStorage.setItem(
      //       "bookingMembershipData",
      //       JSON.stringify(payload)
      //     );
      //     dispatch(addStudentDataReducer(payload));
      //   })();
    } else {
      toast.error(
        "There is something wrong. Kindly delete the items in the cart and book again."
      );
    }
  };

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div
        class="btn-accept btn-view w-auto float-end cursor-pointer"
        style={{ fontSize: "14px" }}
        onClick={() => {
          if (Number(detail?.wallet) < 0) {
            navigate("/make-payment", {
              state: {
                amount: Number(detail?.wallet),
                payment_for: "wallet",
              },
            });
          }
        }}
      >
        <p className="m-0">
          Balance/Credit: <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
          {Number(detail?.wallet)}
        </p>{" "}
        <p className="m-0">
          {Number(detail?.wallet) < 0 ? "(Pay Due Amount)" : ""}
        </p>
      </div>
      <div className="container">
        <BackButton />

        <div className="form-style form-style-updated cartFormStyle">
          <div className="row w-100">
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
              <h3>Cart</h3>
            </div>

            {cartList?.length > 0 &&
              cartList?.map((item, i) => (
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3" key={i}>
                  <div className="bg-white p-4 rounded h-100 shadow">
                    <div className="row align-items-center">
                      <div className="col-sm-12 col-md-6 col-lg-8 mb-2">
                        <div className="event-box-flex">
                          <span>
                            <img
                              src={
                                item?.class_details?.image?.includes("http")
                                  ? item?.class_details?.image
                                  : "assets/img/kick.png"
                              }
                              className="event-img"
                              alt=""
                            />
                          </span>
                          <span>
                            <h4>
                              <a href="event-detail.html">
                                {item?.class_details?.name}
                              </a>
                            </h4>
                            <p className="mb-0">
                              <label className="fw-bold theme-color">
                                Ages:
                              </label>{" "}
                              {item?.time_slot_list?.[0]?.time_slot_for?.toLowerCase() ===
                              "both"
                                ? `Adult | Child`
                                : item?.time_slot_list[0]?.time_slot_for}
                            </p>
                            <p className=" mb-0">
                              <span className="fw-bold theme-color1">
                                School Name:{" "}
                              </span>
                              {item?.school_details?.name}
                            </p>
                            <p className=" mb-0">
                              <span className="fw-bold theme-color1">
                                Disciplines:{" "}
                              </span>

                              {/* {item?.student_list?.map((item) => {
                                return item?.student_details?.discipline_details
                                  ?.name;
                              })?.} */}
                              {item?.student_list
                                ?.map((item) => {
                                  return item?.student_details
                                    ?.discipline_details?.name;
                                })
                                ?.filter(function (item, pos, self) {
                                  return self.indexOf(item) === pos;
                                })
                                ?.join(", ")}
                            </p>

                            <p className=" mb-0">
                              <span className="fw-bold theme-color1">
                                Students:{" "}
                              </span>

                              {/* {item?.student_list?.map((item) => {
                                return item?.student_details?.discipline_details
                                  ?.name;
                              })?.} */}
                              {item?.student_list
                                ?.map((item) => {
                                  return `${item?.student_details?.first_name} ${item?.student_details?.last_name}`;
                                })
                                ?.filter(function (item, pos, self) {
                                  return self.indexOf(item) === pos;
                                })
                                ?.join(", ")}
                            </p>
                            <p className="">
                              <img
                                src="assets/img/address.png"
                                className=""
                                alt=""
                              />{" "}
                              {item?.school_details?.address}
                            </p>
                          </span>
                        </div>
                        <div>
                          <label className="fw-bold theme-color1">
                            Selected Time Slots
                          </label>
                          <p>
                            {item?.time_slot_list?.map((el) => (
                              <p className="mb-0">
                                <span className="fw-bold">Time slot: </span>
                                {moment(el?.start_time, "HH:mm:ss").format(
                                  "hh:mm a"
                                )}
                                -
                                {moment(el?.end_time, "HH:mm:ss").format(
                                  "hh:mm a"
                                )}
                                , {moment(el.date).format("DD MMM YYYY")}
                              </p>
                            ))}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2 text-center">
                        <h3>
                          <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                          {item?.amount ? item?.amount : 0}
                        </h3>
                        <button className="btn btn-submit fs-5 mb-1 w-auto">
                          <img
                            src="assets/img/delete.png"
                            className=""
                            alt=""
                            onClick={() => {
                              setPage("class");
                              setDelId(item?.cart_id);
                              delToggle();
                            }}
                          />
                        </button>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12"></div>
                    </div>
                  </div>
                </div>
              ))}

            {eventList?.cart_data?.length > 0 &&
              eventList?.cart_data?.map((item, i) => (
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3" key={i}>
                  <div className="bg-white p-4 rounded h-100 shadow">
                    <div className="row align-items-center">
                      <div className="col-sm-12 col-md-6 col-lg-9 mb-2">
                        <div className="event-box-flex">
                          <span>
                            <img
                              src={
                                item?.event_details?.image?.includes("http")
                                  ? item?.event_details?.image
                                  : "assets/img/kick.png"
                              }
                              className="event-img"
                              alt=""
                            />
                          </span>
                          <span>
                            <h4>
                              <Link to={`/event/${item?.eventId}`}>
                                {item?.event_details?.name}
                              </Link>
                            </h4>
                            <p className="mb-0">
                              {item?.event_details?.age_group?.toLowerCase() ===
                              "both"
                                ? " Adult | Child"
                                : item?.event_details?.age_group}
                            </p>
                            <p className="mb-0">
                              <span className=" fw-bold theme-color1">
                                Venue:{" "}
                              </span>
                              {item?.school_details?.name}
                            </p>
                            <p className=" mb-0">
                              <span className="fw-bold theme-color1">
                                Students:{" "}
                              </span>

                              {/* {item?.student_list?.map((item) => {
                                return item?.student_details?.discipline_details
                                  ?.name;
                              })?.} */}
                              {[
                                ...new Set(
                                  item?.student_list
                                    ?.map((item) => {
                                      return `${item?.student_details?.first_name} ${item?.student_details?.last_name}`;
                                    })
                                    ?.join(", ")
                                ),
                              ]}
                            </p>
                            <p className="mb-0">
                              <span className="fw-bold theme-color1">On:</span>{" "}
                              {item?.date
                                ? moment(item?.date).format("DD MMM YYYY")
                                : "NA"}
                            </p>
                            <p className="">
                              <img
                                src="assets/img/address.png"
                                className=""
                                alt=""
                              />{" "}
                              {item?.school_details?.address}
                            </p>
                          </span>
                        </div>

                        <p>
                          <span style={{ whiteSpace: "pre-line" }}>
                            <label className="fw-bold">About Event: </label>{" "}
                            {item?.event_details?.about && (
                              <ReadMore>{item?.event_details?.about}</ReadMore>
                            )}
                          </span>
                        </p>
                        <hr />

                        {item?.levels?.length > 0 && (
                          <p className="mb-0">
                            <label className="fw-bold theme-color1">
                              {item?.levels?.length === 1
                                ? "Selected Level"
                                : "Selected Levels"}
                            </label>
                            <div className="p-2">
                              {item?.levels?.map((res, i) => {
                                return (
                                  <div className="mb-2">
                                    <div className="d-flex justify-content-between gap-3">
                                      <span className="mb-0">
                                        <label className="fw-bold">
                                          Level Name:
                                        </label>{" "}
                                        <span>{res?.level_details?.name}</span>
                                      </span>
                                      <span className="mb-0">
                                        <label className="fw-bold">Time:</label>{" "}
                                        <span>
                                          {moment(
                                            res?.level_details?.time,
                                            "hh:mm:ss"
                                          ).format("hh:mm a")}
                                        </span>
                                      </span>
                                    </div>
                                    <span style={{ whiteSpace: "pre-line" }}>
                                      <label className="fw-bold">Note: </label>{" "}
                                      {res?.level_details?.note && (
                                        <ReadMore>
                                          {res?.level_details?.note}
                                        </ReadMore>
                                      )}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </p>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2 text-center">
                        <h3>
                          <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                          {item?.amount
                            ? item?.amount
                            : item?.event_details?.event_price
                            ? item?.event_details?.event_price
                            : 0}
                        </h3>
                        <button className="btn btn-submit fs-5 mb-1 w-auto">
                          <img
                            src="assets/img/delete.png"
                            className=""
                            alt=""
                            onClick={() => {
                              setPage("event");
                              setDelId(item?.cart_id);
                              delToggle();
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {!eventList?.cart_data && !cartList ? (
              <div className="text-center">No item added to cart.</div>
            ) : null}

            {(eventList?.cart_data || cartList) && (
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="bg-white text-end p-3 shadow">
                  <p className="d-flex justify-content-between">
                    <label>Net Total</label>
                    <span>
                      <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                      {amounts?.total_amount}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <label>Admin Charges</label>
                    <span>
                      <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                      {amounts?.admin_amount}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <label>Wallet Amount</label>
                    <span>
                      <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                      {amounts?.wallet_amount}
                    </span>
                  </p>
                  <hr />
                  <p className="d-flex justify-content-between">
                    <label>Total Payable Amount</label>
                    <span>
                      <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                      {amounts?.wallet_amount >
                      amounts?.total_amount + amounts?.admin_amount
                        ? 0
                        : parseFloat(
                            amounts?.total_amount +
                              amounts?.admin_amount -
                              amounts?.wallet_amount
                          )?.toFixed(2)}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {(eventList?.cart_data?.length > 0 || cartList?.length > 0) && (
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                {/* <p>
                  On proceeding further, you agree to the terms and conditions
                  applied for{" "}
                  <span
                    className="theme-color cursor-pointer fw-bold"
                    onClick={() => {
                      navigate("/terms-and-condition/purchase lessons");
                    }}
                  >
                    Purchasing Lessons
                  </span>{" "}
                  and{" "}
                  <span
                    className="theme-color cursor-pointer fw-bold"
                    onClick={() => {
                      navigate("/terms-and-condition/purchase event");
                    }}
                  >
                    Purchasing Events
                  </span>
                </p> */}

                <div className="input-container term-checkbox ">
                  <input
                    type="checkbox"
                    id="termCheckbox"
                    name="tnc"
                    checked={tnc ? tnc : false}
                    // {...register("tnc")}
                    onChange={(e) => {
                      setTnc(e.target.checked);
                    }}
                  />
                  <label htmlFor="termCheckbox"></label>
                  <span>
                    I agree to the terms and conditions applied for{" "}
                    <span
                      className="theme-color cursor-pointer fw-bold"
                      onClick={() => {
                        // navigate("/terms-and-condition/purchase lessons");
                        window.open(
                          "/terms-and-condition/purchase lessons",
                          "_blank"
                        );
                      }}
                    >
                      Purchasing Lessons
                    </span>{" "}
                    and{" "}
                    <span
                      className="theme-color cursor-pointer fw-bold"
                      onClick={() => {
                        // navigate("/terms-and-condition/purchase event");
                        window.open(
                          "/terms-and-condition/purchase event",
                          "_blank"
                        );
                      }}
                    >
                      Purchasing Events
                    </span>
                  </span>
                  {error && <div className="text-red">{error}</div>}
                </div>
              </div>
            )}

            {(eventList?.cart_data?.length > 0 || cartList?.length > 0) && (
              <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="view-btns">
                  <Link
                    to={cartList?.length > 0 ? "/book-class" : "/dashboard"}
                    className="w-100"
                  >
                    <button className="btn btn-reject btn-view mt-2">
                      Book More
                    </button>
                  </Link>
                  <button
                    className="btn btn-accept btn-view mt-2"
                    disabled={tnc === false || submitting === true}
                    onClick={() => {
                      // setModalName("selectPayment");
                      // handleShow();
                      handlePayment();
                      // if (amounts?.wallet_amount < 0) {
                      //   navigate("/make-payment", {
                      //     state: {
                      //       amount: Number(detail?.wallet),
                      //       payment_for: "wallet",
                      //     },
                      //   });
                      // } else if (
                      //   amounts?.wallet_amount <
                      //   amounts?.total_amount + amounts?.admin_amount
                      // ) {
                      //   const amt =
                      //     amounts?.total_amount +
                      //     amounts?.admin_amount -
                      //     amounts?.wallet_amount;
                      //   navigate("/make-payment", {
                      //     state: {
                      //       amount: amt,
                      //       payment_for: "wallet",
                      //     },
                      //   });
                      // } else {
                      //   if (
                      //     cartList?.length > 0 ||
                      //     eventList?.cart_data?.length > 0
                      //   ) {

                      //   } else {
                      //     toast.error(
                      //       "Kindly add items to the cart for payment."
                      //     );
                      //   }
                      // }
                    }}
                    id="pro-payment"
                  >
                    {submitting ? <Loader /> : " Proceed to Payment"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {modalName === "selectPayment" && (
        <SelectPayment
          show={show}
          setBookingId={setBookingId}
          setPage={setPage}
          page={page}
          handleShow={(val) => {
            if (val !== undefined && val?.length !== 0) {
              setModalName(val);
            } else {
              handleShow();
            }
          }}
          wallet_used_amount={
            amounts?.wallet_amount === 0
              ? 0
              : amounts.wallet_amount >=
                amounts?.total_amount + amounts?.admin_amount
              ? amounts?.total_amount + amounts?.admin_amount
              : amounts.total_amount +
                amounts.admin_amount -
                amounts?.wallet_amount
          }
          total_amount={amounts?.total_amount}
        />
      )} */}

      {modalName === "orderSummary" && bookingId && (
        <OrderSummary
          show={show}
          handleShow={(val) => {
            handleShow();
            if (val !== "" || val !== undefined) {
              setModalName("");
              navigate("/book-class", { replace: true });
            }
          }}
          id={bookingId}
          page={page}
        />
      )}

      {delId && (
        <Delete
          show={delOpen}
          handleShow={(stat) => {
            if (stat === "confirm") {
              confirmDelete();
            }
            setDelId("");
            delToggle();
          }}
        />
      )}
    </section>
  );
}

export default Cart;
