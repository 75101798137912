import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import StudentCardFull from "../../component/student/StudentCardFull";
import { GetStudentList, studentListSelector } from "../slice";


//For Membership
import MembershipTNC from "../../component/Modal/MembershipTNC";
import ReadMore from "../../component/ReadMore/ReadMore";
import { useToggle } from "../../utils/hooks";
import moment from "moment";
import {
  addMemberShip,
  FetchMemberShipList,
  FetchMyMemberShipList,
  membershipListSelector,
  userDetailSelector,
} from "../slice";

import {
  changeDiscipline,
  changeStudentData,
  changeStudentType,
} from "../membership/MembershipSlice";

function AllStudent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studentList = useSelector(studentListSelector);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('students');
  const [activeOption, setActiveOption] = useState(null);
  //For Membership
  const list = useSelector((state) => state.main.myMembershipList);
  const trialStudentsList = useSelector(
    (state) => state.main.trialStudentsList
  );
  const [selectedMembershipId, setSelectedMembershipId] = useState("");
  const [show, toggle] = useToggle(false);
  const [tnc, setTnc] = useState("");
  const user = useSelector(userDetailSelector);
  const [useWalletAmount, setUseWalletAmount] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedTncIndex, setSelectedTncIndex] = useState("");
  const [errors, setErrors] = useState("");



  const getStudentList = useCallback(() => {
    setLoading(true);
    dispatch(GetStudentList({ all:true })).then(() => {
      setLoading(false);
    });
  }, [dispatch]);
  useEffect(() => {
    getStudentList();
  }, [getStudentList]);

  
  useEffect(() => {
    dispatch(FetchMyMemberShipList());
  }, [dispatch]);


  const handleRenewalDate = (data) => {
    const expiryDate = moment(data?.valid_till);
    const today = moment();
    let difference = expiryDate.diff(today, "days");
    console.log(difference, "Data");
    if (!data?.student_details?.deletedAt) {
      if (
        (Number(data?.membership_details?.no_of_days) > 30 &&
          difference < 30) ||
        (Number(data?.membership_details?.no_of_days) === 7 &&
          difference < 2) ||
        (Number(data?.membership_details?.no_of_days) === 1 &&
          difference < 1) ||
        difference < 0
      ) {
        return true;
      }
    }
    return false;
  };

  const handleExpired = (data) => {
    console.log(data, "Data");
    const expiryDate = moment(data?.valid_till);
    const today = moment();
    let difference = expiryDate.diff(today, "days");

    if (difference < 0) {
      return true;
    }

    return false;
  };

  // const isRenewal = handleRenewalDate();

  const onBuy = (data) => {
    console.log(data);
    if (!tnc) {
      setErrors("Kindly accept the terms and condition");
    } else {
      //  let price =
      //    student_type?.toLowerCase() === "child"
      //      ? Number(memberLevel?.child_price)
      //      : student_type?.toLowerCase() === "adult"
      //      ? Number(memberLevel?.adult_price)
      //      : 0;
      //  let walletUsedAmount =
      //    useWalletAmount && Number(user?.wallet) >= price
      //      ? price
      //      : useWalletAmount &&
      //        Number(user?.wallet) > 0 &&
      //        Number(user?.wallet) <= price
      //      ? Number(user?.wallet)
      //      : 0;
      //  let transaction_amount = price - walletUsedAmount;
      //  let payload;
      //  if (useWalletAmount) {
      //    payload = {
      //      data: {
      //        membership_level: memberLevel?.id,
      //        studentId: id,
      //        no_of_days: memberLevel?.no_of_days,
      //        price: price,
      //        wallet_used_amount: walletUsedAmount,
      //        transaction_amount,
      //      },
      //      payment_for: "membership",
      //      sub_payment_for: "changePlan",
      //      api: "auth/addMemberShipLevel",
      //    };
      //  } else {
      //    payload = {
      //      data: {
      //        membership_level: memberLevel?.id,
      //        studentId: id,
      //        no_of_days: memberLevel?.no_of_days,
      //        price: price,
      //        wallet_used_amount: "",
      //        transaction_amount: price,
      //      },
      //      payment_for: "membership",
      //      sub_payment_for: "changePlan",
      //      api: "auth/addMemberShipLevel",
      //    };
      //  }
      //  setLoading(true);
      //  if (!useWalletAmount) {
      //    (async () => {
      //      await localStorage.setItem(
      //        "addStudentWithMembershipPayload",
      //        JSON.stringify(payload)
      //      );
      //      dispatch(addStudentDataReducer(payload));
      //    })();
      //    postFormData("booking/createPaymentLink", {
      //      amount: payload.data?.transaction_amount,
      //      currency: "GBP",
      //      merchant_support_email: user?.email,
      //      payment_for: "membership",
      //      membership_id: memberLevel?.id,
      //      student_type: student_type,
      //      wallet_used_amount: 0,
      //    }).then((res) => {
      //      console.log(res, "Create payment api");
      //      if (res?.code === 1) {
      //        let URI = res?.data?.result?.paymentLink?.url;
      //        window.open(URI, "_self");
      //      } else {
      //        toast.error(res?.message);
      //      }
      //    });
      //  } else if (useWalletAmount) {
      //    if (Number(user?.wallet) >= payload?.data?.price) {
      //      dispatch(addMemberShip(payload?.data)).then((res) => {
      //        setLoading(false);
      //        if (res?.payload?.code === 1) {
      //          // setShowPayment(true);
      //          navigate("/student", { replace: true });
      //          toast.success(res?.payload?.message);
      //        } else {
      //          toast.error(res?.payload?.message);
      //        }
      //      });
      //    } else {
      //      (async () => {
      //        await localStorage.setItem(
      //          "addStudentWithMembershipPayload",
      //          JSON.stringify(payload)
      //        );
      //        dispatch(addStudentDataReducer(payload));
      //      })();
      //      postFormData("booking/createPaymentLink", {
      //        amount: payload.data?.transaction_amount,
      //        currency: "GBP",
      //        merchant_support_email: user?.email,
      //        payment_for: "membership",
      //        membership_id: memberLevel?.id,
      //        student_type: student_type,
      //        wallet_used_amount: walletUsedAmount,
      //      }).then((res) => {
      //        console.log(res, "Create payment api");
      //        if (res?.code === 1) {
      //          let URI = res?.data?.result?.paymentLink?.url;
      //          window.open(URI, "_self");
      //        } else {
      //          toast.error(res?.message);
      //        }
      //      });
      //    }
      //  }
    }
  };

  const handleBuy = () => {};
  const isActiveMembership = (item) => {
    return (
      item?.student_details?.trialAccountStatus === "3" &&
      item?.payment_status === "1"
    );
  };
  const isExpiredHandler = (item) => {
    const expiryDate = moment(item?.valid_till);
    const today = moment();
    let difference = moment.duration(expiryDate.diff(today)).asHours();

    if (difference <= 0) {
      return true;
    }
    return false;
  };


  return (
    <>
      {/* <section className="mt-4">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="btn-flex">
                <a
                  href="make-bookings.html"
                  className="white-btn shadow tanitems"
                >
                  Make Bookings
                </a>
                <Link to="/all-student" className="white-btn shadow tanitems">
                  All Students
                </Link>
                <a href="my-booking.html" className="white-btn shadow tanitems">
                  My Bookings
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="view-btns justify-content-end">
                <span className="btn-accept btn-view w-auto">
                  Balance/Credit:
                  <i className="fa fa-gbp" aria-hidden="true"></i> 24
                </span>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/*<section className="sec-bg py-4 px-3 bottom-space">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
              <h3>All Students</h3>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 text-end">
              <Link to="/add-student" className="h3">
                + Add New Student
              </Link>
            </div>

            {studentList?.map((item, i) => (
              <div className="col-sm-12 col-md-6 col-lg-4 mt-3" key={i}>
                <StudentCardFull data={item} refresh={getStudentList} />
              </div>
            ))}
          </div>
        </div>
      </section>*/}
     
      {/* Add tab navigation */}
      <section className="sec-bg py-4 px-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button 
                    className={`nav-link ${activeTab === 'students' ? 'active' : ''}`}
                    onClick={() => setActiveTab('students')}
                  >
                    All Students
                  </button>
                </li>
                <li className="nav-item">
                  <button 
                    className={`nav-link ${activeTab === 'memberships' ? 'active' : ''}`}
                    onClick={() => setActiveTab('memberships')}
                  >
                    Memberships
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Students Section */}
      {activeTab === 'students' && (
        <section className="sec-bg py-4 px-3 bottom-space">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
                <h3>All Students</h3>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 text-end">
                <Link to="/add-student" className="h3">
                  + Add New Student
                </Link>
              </div>

              {studentList?.map((item, i) => (
                <div className="col-sm-12 col-md-6 col-lg-4 mt-3" key={i}>
                  <StudentCardFull data={item} refresh={getStudentList}  activeOption={activeOption}
          setActiveOption={setActiveOption}/>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      {/* Memberships Section */}
      {activeTab === 'memberships' && (
        <section className="sec-bg py-4 px-3 bottom-space">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3>Memberships</h3>
                {/* Add your memberships content here */}

                <section
      className="sec-bg py-4 px-3 bottom-space"
      style={{
        height:
          list?.length === 0 && trialStudentsList?.length == 0 ? "50vh" : "",
      }}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>My Membership</h3>
          </div>
        </div>

        <div className="row justify-content-center">
          {trialStudentsList?.length > 0 ? (
            trialStudentsList?.map((item, i) => {
              const isRenewal = handleRenewalDate(item);
              // const isExpired = handleExpired(item);

              return (
                <div
                  className="col-sm-12 col-md-6 col-lg-4 mt-5 mb-5"
                  key={item?.id}
                >
                  <div className="myMembership membership-step-box h-auto">
                    <div className="text-center">
                      <img
                        src={
                          item?.image?.includes("http")
                            ? item?.image
                            : "assets/img/membership-icon.png"
                        }
                        className="membership-icon"
                        alt=""
                      />
                    </div>
                    <h5 className="text-capitalize text-center">
                      {item?.discipline_details?.name}
                    </h5>
                    <h2 className="text-center fs-3">
                      <span className="theme-color fw-bold">
                        {item?.student_type === "adult" ? (
                          <>
                            {/* <i className="fa fa-gbp" aria-hidden="true"></i>{" "} */}
                            Adult
                          </>
                        ) : item?.student_type === "child" ? (
                          <>
                            {/* <i className="fa fa-gbp" aria-hidden="true"></i>{" "} */}
                            Child
                          </>
                        ) : (
                          "NA"
                        )}
                      </span>
                    </h2>
                    <p className="text-center">
                      <small className="text-uppercase theme-color fs-6">
                        TRIAL
                      </small>
                    </p>
                    {/* <ul className="mem-li">
                      <ReadMore>{item?.membership_details?.about}</ReadMore>
                    </ul>
                    <p className="text-center">
                      <label className="theme-color">Duration: </label>{" "}
                      {item?.membership_details?.duration}{" "}
                      {item?.membership_details?.time_period}
                    </p> */}
                    {/* <span
                      className="text-capitalize text-center theme-color cursor-pointer fw-bold"
                      onClick={() => {
                        setSelectedMembershipId(item?.id);
                        setTnc(item?.terms_and_condition);
                        toggle();
                      }}
                    >
                      <p className="text-center">terms & Condition apply</p>
                    </span> */}

                    <span
                      className="text-capitalize text-center cursor-pointer text-center"
                      // onClick={() => {
                      //   setShowTNC(!showTNC);
                      // }}
                    >
                      <p>
                        <label className=" fw-bold theme-color">Student:</label>{" "}
                        {`${item?.first_name} ${item?.last_name}`}
                      </p>
                    </span>

                    {/* {showTNC && (
                      <div className="bg-white p-3 mt-3">
                        <p>{item?.terms_and_condition}</p>
                      </div>
                    )} */}
                    <p className="d-grid text-center">
                      {/* <span>Status</span> */}
                      {/* <span
                        onClick={() => {
                          (async () => {
                            await localStorage.setItem(
                              "discipline",
                              item?.discipline_details?.id
                            );
                            await localStorage.setItem(
                              "student_type",
                              item?.student_type
                            );
                            await localStorage.setItem(
                              "student_data",
                              JSON.stringify(item)
                            );
                            await localStorage.setItem("buyOrRenew", "buy");
                          })();
                          dispatch(changeStudentData(item));

                          dispatch(
                            changeDiscipline(item?.discipline_details?.id)
                          );
                          dispatch(changeStudentType(item?.student_type));

                          navigate(`/plans/${item?.id}`);
                        }}
                      >
                        <b className={"text-danger"}>
                          {item?.trialAccountStatus === "1"
                            ? "Upgrade to full membership"
                            : item?.trialAccountStatus === "2"
                            ? "Purchase Membership"
                            : ""}
                        </b>
                      </span> */}

                      <span>
                        <small className={"text-danger"}>
                          {item?.trialAccountStatus === "1"
                            ? ""
                            : item?.trialAccountStatus === "2"
                            ? "Trial Expired"
                            : ""}
                        </small>
                      </span>
                    </p>
                  </div>
                  <button
                    type="button"
                    className="btn btn-submit fs-6 mb-1 w-100"
                    onClick={() => {
                      (async () => {
                        await localStorage.setItem(
                          "discipline",
                          item?.discipline_details?.id
                        );
                        await localStorage.setItem(
                          "student_type",
                          item?.student_type
                        );
                        await localStorage.setItem(
                          "student_data",
                          JSON.stringify(item)
                        );
                        await localStorage.setItem("buyOrRenew", "buy");
                      })();
                      dispatch(changeStudentData(item));

                      dispatch(changeDiscipline(item?.discipline_details?.id));
                      dispatch(changeStudentType(item?.student_type));

                      navigate(`/plans/${item?.id}`);
                    }}
                  >
                    {item?.trialAccountStatus === "1"
                      ? "Upgrade to full membership"
                      : item?.trialAccountStatus === "2"
                      ? "Purchase Membership"
                      : ""}
                  </button>
                </div>
              );
            })
          ) : (
            <div className="text-center"> </div>
          )}
        </div>

        <div className="row justify-content-center">
          {list?.length > 0 ? (
            list
              ?.filter((it) => it?.isActive === true)
              ?.map((item, i) => {
                const isRenewal = handleRenewalDate(item);
                // const isExpired = handleExpired(item);

                return (
                  <div
                    className="col-sm-12 col-md-6 col-lg-4 mt-5 mb-5"
                    key={item?.id}
                  >
                    <div className="myMembership membership-step-box h-auto">
                      <div className="text-center">
                        <img
                          src={
                            item?.membership_details?.image?.includes("http")
                              ? item?.membership_details?.image
                              : "assets/img/membership-icon.png"
                          }
                          className="membership-icon"
                          alt=""
                        />
                      </div>
                      <h5 className="text-capitalize text-center">
                        {item?.membership_details?.name}
                      </h5>
                      <h2 className="text-center fs-3">
                        <span className="theme-color fw-bold">
                          {item?.membership_details?.student_type ===
                          "adult" ? (
                            <>
                              <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                              Adult: {item?.membership_details?.adult_price}
                            </>
                          ) : item?.membership_details?.student_type ===
                            "child" ? (
                            <>
                              <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                              Child: {item?.membership_details?.child_price}
                            </>
                          ) : (
                            "NA"
                          )}
                        </span>
                      </h2>
                      <p className="text-center">
                        <small className="text-uppercase theme-color fs-6">
                          per annum
                        </small>
                      </p>
                      <ul className="mem-li">
                        <ReadMore>{item?.membership_details?.about}</ReadMore>
                      </ul>
                      <p className="text-center">
                        <label className="theme-color">Duration: </label>{" "}
                        {item?.membership_details?.duration}{" "}
                        {item?.membership_details?.time_period}
                      </p>
                      {/* <span
                      className="text-capitalize text-center theme-color cursor-pointer fw-bold"
                      onClick={() => {
                        setSelectedMembershipId(item?.id);
                        setTnc(item?.terms_and_condition);
                        toggle();
                      }}
                    >
                      <p className="text-center">terms & Condition apply</p>
                    </span> */}

                      <span
                        className="text-capitalize text-center cursor-pointer text-center"
                        // onClick={() => {
                        //   setShowTNC(!showTNC);
                        // }}
                      >
                        <p>
                          <label className=" fw-bold theme-color">
                            Student:
                          </label>{" "}
                          {item?.student_details
                            ? `${item?.student_details?.first_name} ${item?.student_details?.last_name}`
                            : ""}
                        </p>
                      </span>

                      {/* {showTNC && (
                      <div className="bg-white p-3 mt-3">
                        <p>{item?.terms_and_condition}</p>
                      </div>
                    )} */}
                      <p className="d-grid text-center">
                        {/* <span>Status</span> */}
                        <span>
                          <b
                            className={
                              (item?.student_details?.trialAccountStatus ===
                                "3" &&
                                isExpiredHandler(item)) ||
                              (item?.student_details?.trialAccountStatus ===
                                "3" &&
                                !isActiveMembership(item)) ||
                              item?.student_details?.trialAccountStatus ===
                                "0" ||
                              item?.student_details?.trialAccountStatus === "2"
                                ? "text-danger"
                                : ""
                            }
                          >
                            {item?.student_details?.trialAccountStatus === "1"
                              ? "Trial"
                              : item?.student_details?.trialAccountStatus ===
                                "2"
                              ? "Trial Expired"
                              : item?.student_details?.trialAccountStatus ===
                                  "0" || item?.membership_details === null
                              ? "No membership"
                              : (item?.student_details?.trialAccountStatus ===
                                  "3" &&
                                  isExpiredHandler(item)) ||
                                (item?.student_details?.trialAccountStatus ===
                                  "3" &&
                                  item?.membership_details === null) ||
                                (item?.student_details?.trialAccountStatus ===
                                  "3" &&
                                  !isActiveMembership(item))
                              ? "Expired"
                              : item?.student_details?.trialAccountStatus ===
                                  "3" &&
                                !isExpiredHandler(item) &&
                                isActiveMembership(item)
                              ? ""
                              : ""}
                          </b>
                        </span>
                      </p>

                      {item?.student_details?.trialAccountStatus === "1" ||
                      item?.student_details?.trialAccountStatus === "2" ||
                      item?.student_details?.trialAccountStatus === "0" ||
                      (item?.student_details?.trialAccountStatus === "3" &&
                        isExpiredHandler(item)) ||
                      (item?.student_details?.trialAccountStatus === "3" &&
                        !isActiveMembership(item)) ? (
                        <p
                          className={"theme-color fw-bold text-center"}
                          onClick={() => {
                            (async () => {
                              await localStorage.setItem(
                                "discipline",
                                item?.student_details?.discipline
                              );
                              await localStorage.setItem(
                                "student_type",
                                item?.student_details?.student_type
                              );
                              await localStorage.setItem(
                                "student_data",
                                JSON.stringify(item?.student_details)
                              );
                              await localStorage.setItem("buyOrRenew", "buy");
                            })();
                            dispatch(changeStudentData(item?.student_details));

                            dispatch(
                              changeDiscipline(
                                item?.student_details?.discipline
                              )
                            );
                            dispatch(
                              changeStudentType(
                                item?.student_details?.student_type
                              )
                            );

                            // navigate(`/plans/${item?.student_details?.id}`);
                          }}
                        >
                          <p
                            className={
                              item?.student_details?.trialAccountStatus ===
                                "0" ||
                              (item?.student_details?.trialAccountStatus ===
                                "3" &&
                                !isActiveMembership(item)) ||
                              (item?.student_details?.trialAccountStatus ===
                                "3" &&
                                isExpiredHandler(item)) ||
                              item?.student_details?.trialAccountStatus ===
                                "2" ||
                              item?.membership_details === null
                                ? "text-danger "
                                : ""
                            }
                          >
                            {item?.student_details?.trialAccountStatus ===
                              "2" ||
                            item?.student_details?.trialAccountStatus === "1"
                              ? ""
                              : item?.student_details?.trialAccountStatus ===
                                  "0" ||
                                (item?.student_details?.trialAccountStatus ===
                                  "3" &&
                                  !isActiveMembership(item)) ||
                                (item?.student_details?.trialAccountStatus ===
                                  "3" &&
                                  isExpiredHandler(item)) ||
                                item?.membership_details === null
                              ? "Upgrade to full membership"
                              : ""}
                          </p>
                        </p>
                      ) : // : isRenewal ? (
                      //   <span
                      //     className="theme-color cursor-pointer fw-bold"
                      //     onClick={() => {
                      //       (async () => {
                      //         await localStorage.setItem(
                      //           "discipline",
                      //           item?.membership_details?.discipline_details?.id
                      //         );
                      //         await localStorage.setItem(
                      //           "student_type",
                      //           item?.student_details?.student_type
                      //         );
                      //         await localStorage.setItem(
                      //           "student_data",
                      //           JSON.stringify(item?.student_details)
                      //         );
                      //         await localStorage.setItem("buyOrRenew", "renew");
                      //       })();
                      //       dispatch(
                      //         changeDiscipline(
                      //           item?.membership_details?.discipline_details?.id
                      //         )
                      //       );
                      //       dispatch(
                      //         changeStudentType(
                      //           item?.student_details?.student_type
                      //         )
                      //       );
                      //       dispatch(changeStudentData(item?.student_details));
                      //       navigate(`/plans/${item?.student_details?.id}`, {
                      //         state: {
                      //           membershipId:
                      //             item?.membership_details?.membership_level,
                      //         },
                      //       });
                      //     }}
                      //   >
                      //     Renew Membership
                      //   </span>
                      // )
                      null}
                      <span>
                        <small
                          className={
                            (item?.student_details?.trialAccountStatus ===
                              "3" &&
                              isRenewal) ||
                            (item?.student_details?.trialAccountStatus ===
                              "3" &&
                              isExpiredHandler(item))
                              ? "text-danger"
                              : ""
                          }
                        >
                          {item?.student_details?.trialAccountStatus === "3" &&
                          item?.membership_details !== null &&
                          isExpiredHandler(item)
                            ? `Your membership is expired. Kindly renew.`
                            : item?.student_details?.trialAccountStatus ===
                                "3" &&
                              item?.membership_details !== null &&
                              isRenewal
                            ? `Your membership expires soon. Kindly renew.`
                            : ""}
                        </small>
                      </span>
                    </div>

                    {isRenewal && (
                      <>
                        {/* {Number(user?.wallet) > 0 && (
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 ">
                          <div className="input-container term-checkbox ">
                            <input
                              type="checkbox"
                              id={`termCheckbox2${i}`}
                              name="tnc"
                              checked={i === selectedIndex && useWalletAmount}
                              // {...register("tnc")}
                              onChange={(e) => {
                                setUseWalletAmount(e.target.checked);
                                setSelectedIndex(i);
                              }}
                            />
                            <label htmlFor={`termCheckbox2${i}`}></label>
                            <span>Use wallet amount</span>
                          </div>
                        </div>
                      )} */}
                        {/* <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center ">
                        <div className="input-container term-checkbox ">
                          <input
                            type="checkbox"
                            id={`${i}termCheckbox`}
                            name="tnc"
                            checked={selectedTncIndex === i && tnc}
                            // {...register("tnc")}
                            onChange={(e) => {
                              setSelectedTncIndex(i);
                              setTnc(e.target.checked);
                              if (e.target.checked) {
                                setErrors("");
                              }
                            }}
                          />
                          <label htmlFor={`${i}termCheckbox`}></label>
                          <span>
                            I accept the{" "}
                            <span
                              className="theme-color fw-bold"
                              onClick={() => {
                                navigate(
                                  "/terms-and-condition/purchase membership"
                                );
                              }}
                            >
                              TERMS & CONDITION
                            </span>{" "}
                            for purchasing membership.
                          </span>
                          {errors && <div className="text-red">{errors}</div>}
                        </div>
                      </div> */}

                        <button
                          type="button"
                          className="btn btn-submit fs-6 mb-1 w-100"
                          onClick={() => {
                            (async () => {
                              await localStorage.setItem(
                                "discipline",
                                item?.student_details?.discipline
                              );
                              await localStorage.setItem(
                                "student_type",
                                item?.student_details?.student_type
                              );
                              await localStorage.setItem(
                                "student_data",
                                JSON.stringify(item?.student_details)
                              );
                              await localStorage.setItem("buyOrRenew", "renew");
                            })();
                            dispatch(changeStudentData(item?.student_details));

                            dispatch(
                              changeDiscipline(
                                item?.student_details?.discipline
                              )
                            );
                            dispatch(
                              changeStudentType(
                                item?.student_details?.student_type
                              )
                            );

                            navigate(`/plans/${item?.student_details?.id}`, {
                              state: {
                                membershipId: item?.membership_details?.id,
                              },
                            });
                          }}
                        >
                          Renew Plan
                        </button>
                      </>
                    )}
                    {}

                    {/* <div className="text-center mt-3">
                    <Link to="/plans" className="fw-bold">
                      Change Plan
                    </Link>
                  </div> */}
                  </div>
                );
              })
          ) : (
            <div className="text-center">No membership added.</div>
          )}
        </div>
      </div>
      {selectedMembershipId && tnc && (
        <MembershipTNC
          show={show}
          handleShow={(val) => {
            if (val === "hide") {
              setSelectedMembershipId(null);
              setTnc(null);
            }
            toggle();
          }}
          data={tnc}
        />
      )}
    </section>



              </div>
            </div>
          </div>
        </section>
      )}
    

    </>
  );
}

export default AllStudent;
